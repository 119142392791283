<template>
    <section class="select-etapas-configuraciones">
        <navbar-admin icon="icon-shopping"  />
        <!-- info -->
        <div class="row bg-f6 shadow29 justify-content-center py-3">
            <div class="col-5 my-auto">
                <p class="font-weight-bolder text-general f-15">
                    Oportunidad
                    <span class="text-muted pl-3 f-500">
                        {{ matriz.oportunidad && matriz.oportunidad.oportunidad }}
                    </span>
                </p>
            </div>
            <div class="col-5">
                <div class="d-flex">
                    <div class="cont-img wh-74 br-4">
                        <img :src="producto.imagen | helper-storage" alt="imagen-producto" class="w-100 h-100 obj-cover">
                    </div>
                    <div class="ml-2">
                        <p class="font-weight-bolder text-general f-15"> Producto: </p>
                        <p class="text-muted f-500"> {{ producto.nombre }} </p>
                    </div>
                </div>
            </div>
        </div>
        <div class=" container-fluid">
            <titulo-divisor titulo="Elige las etapas">
                <div class="row">
                    <div class="col-auto my-auto px-1">
                        <button class="btn btn-general f-12 px-4"
                            :class="permitAction('compras.seleccionar.etapas.todas') ? 'cr-pointer' : 'cr-not-allowed'"
                            @click="functionPermitAction('compras.seleccionar.etapas.todas', selectAllStages)"
                            >Seleccionar todos</button>
                    </div>
                </div>
            </titulo-divisor>
            <!-- listado etapas -->
            <div class="row stages-list my-5">
                <div v-for="(data, i) in etapas" :key="i" class="col-auto"
                    :class="permitAction('compras.seleccionar.etapa') ? 'cr-pointer' : 'cr-not-allowed'"
                    @click="functionPermitAction('compras.seleccionar.etapa', () => {data.selected = !data.selected})"
                  >
                    <div class="d-middle item-stage shadow29 br-4 cr-pointer" :class="{'selected': data.selected}">
                        <div class="color bg-general br-l-4" />
                        <p class="pl-2 pr-2 f-14 tres-puntos"> {{ data.nombre }} </p>
                    </div>
                </div>
            </div>
            <!-- guardar etapas -->
            <div class="row justify-content-center">
                <div class="col-auto">
                    <button class="btn btn-general f-12 px-4"
                        :class="permitAction('compras.guardar.etapas.seleccionadas') ? 'cr-pointer' : 'cr-not-allowed'"
                        @click="functionPermitAction('compras.guardar.etapas.seleccionadas', saveSelectedStages)"
                    >Aceptar</button>
                </div>
            </div>
            <!-- configuration -->
            <section v-if="showConfigurationsList">
                <titulo-divisor titulo="Elige las configuraciones">
                    <div class="row">
                        <div class="col-auto my-auto px-1">
                            <button class="btn btn-general f-12 px-4"
                                :class="permitAction('compras.seleccionar.configuraciones.todas') ? 'cr-pointer' : 'cr-not-allowed'"
                                @click="functionPermitAction('compras.seleccionar.configuraciones.todas', selectAllConfigurations)"
                             >Seleccionar todos</button>
                        </div>
                    </div>
                </titulo-divisor>
                <!-- listado configuraciones -->
                <div class="row configurations-list my-5">
                    <div v-for="(data, i) in configuraciones" :key="i" class="col-auto mb-4"
                        :class="permitAction('compras.seleccionar.configuracion') ? 'cr-pointer' : 'cr-not-allowed'"
                        @click="functionPermitAction('compras.seleccionar.configuracion', () => {data.selected = !data.selected})"
                    >
                        <div class="item-configuration br-4 shadow29 cr-pointer" :class="{'selected': data.selected}">
                            <p class="tres-puntos text-general f-14 pb-1 text-center pt-1 f-500 border-bottom-g"> {{ data.nombre }} </p>
                            <div class="d-flex justify-content-around py-3">
                                <div class="d-middle">
                                    <i class="icon-cash-multiple" />
                                    <p class="pl-1 f-12 f-500"> {{ formatoMoneda(data.valor) }} </p>
                                </div>
                                <div class="d-middle">
                                    <i class="icon-account-clock" />
                                    <p class="pl-1 f-12 f-500"> {{ data.horas_hombre }} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- guardar configuraciones -->
                <div class="row justify-content-center">
                    <div class="col-auto">
                        <button class="btn btn-general f-12 px-4"
                            :class="permitAction('compras.guardar.configuraciones.seleccionadas') ? 'cr-pointer' : 'cr-not-allowed'"
                            @click="functionPermitAction('compras.guardar.configuraciones.seleccionadas', saveSelectedConfigurations)"
                        >Aceptar</button>
                    </div>
                </div>
            </section>
        </div>
    </section>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import { Notification } from 'element-ui'
export default {
    data() {
        return {
            showConfigurationsList: false,
            matriz: {},
            producto: {},
        }
    },
    computed: {
        ...mapGetters({
            selected_configuraciones: 'compras/ordenes_compra/selected_configuraciones',
        }),
        etapas: {
            get(){
                return this.$store.getters['compras/ordenes_compra/etapas']
            },
            set(val){
                this.$store.commit('compras/ordenes_compra/set_etapas', val)
            }
        },
        configuraciones: {
            get(){
                return this.$store.getters['compras/ordenes_compra/configuraciones']
            },
            set(val){
                this.$store.commit('compras/ordenes_compra/set_configuraciones', val)
            }
        },
        someStagesWereSelected(){
            return this.etapas.some(el => el.selected)
        },
        someConfigWereSelected(){
            return this.configuraciones.some(el => el.selected)
        }
    },
    async mounted(){
        const orden_compra = JSON.parse(localStorage.getItem('orden_compra'))
        this.matriz = orden_compra.matriz
        this.producto = orden_compra.producto
        await this.obtenerEtapas({
            id_ops: this.matriz.oportunidad.id,
            id_producto: this.producto.id,
            tabla: this.producto.tabla
        })
    },
    methods: {
        ...mapActions({
            obtenerEtapas: 'compras/ordenes_compra/Action_get_etapas',
            obtenerConfiguraciones: 'compras/ordenes_compra/Action_get_configuraciones',
        }),
        selectAllStages(){
            this.etapas.forEach(etapa => etapa.selected = true)
        },
        selectAllConfigurations(){
            this.configuraciones.forEach(configuracion => configuracion.selected = true)
        },
        async saveSelectedStages(){
            const selectEtapas = this.etapas.find(el => {
                return el.selected == true
            })

            if(!selectEtapas) {
                new Notification({
                    message:'No se encontraron etapas seleccionadas',
                    type:'error',
                    dangerouslyUseHTMLString: false
                })
                return
            }
            let payload = this.etapas.filter(el => el.selected)
            payload = payload.map(el => {
                if (el.selected) return el.info
            })
            console.log("🚀 ~ file: selectEtapasConfiguraciones.vue:179 ~ payload ~ payload", payload)
            this.showConfigurationsList = true
            if(this.someStagesWereSelected){
                await this.obtenerConfiguraciones(payload)
            }
        },
        saveSelectedConfigurations(){
            const selectConfiguraciones = this.configuraciones.find(el => el.selected == true)
            if(!selectConfiguraciones) {
                new Notification({
                    message:'No se encontraron configuraciones seleccionadas',
                    type:'error',
                    dangerouslyUseHTMLString: false
                })
                return
            }
            localStorage.setItem('orden_compra', JSON.stringify({
                matriz: this.matriz,
                producto: this.producto,
                configuraciones: this.selected_configuraciones
            }))
            this.$router.push({ name: 'compras.crear.matriz.proveedores' })
        }
    },
}
</script>

<style lang="scss" scoped>
.select-etapas-configuraciones{
    .cont-img{
        border: 2px solid var(--color-general)
    }
    .stages-list{
        .item-stage{
            max-width: 150px;
            min-width: 150px;
            .color{
                min-width: 6px;
                height: 27px;
            }
            
        }
    }
    .configurations-list{
        .item-configuration{
            max-width: 219px;
            min-width: 219px;
        }
    }
    .selected{
        background: #F6F6F6 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000038 !important;
    }
}
</style>